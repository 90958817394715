<template>
  <el-main class="privacy-container">
    <div class="inner-container">
      <div class="section-h2">
        <MainContain />
        {{ $t('Privacy.Title_Subject') }}
      </div>
      <section class="content-section">
        {{ $t('Privacy.Desc_Subject') }} 
      </section>
      <section class="content-section">
        <div class="section-h3">{{ $t('Privacy.Title_Section1') }} </div>
        <div class="content-desc">
          {{ $t('Privacy.Desc_Section1') }} 
        </div>
        <div class="section-h3">{{ $t('Privacy.Title_Section2') }} </div>
        <ol>
          <li>{{ $t('Privacy.Desc1_Section2') }}</li>
          <li>{{ $t('Privacy.Desc2_Section2') }}</li>
          <li>
            {{ $t('Privacy.Desc3_Section2') }}
            <div>
              {{ $t('Privacy.Desc3_1_Section2') }}
            </div>
            <div>
              {{ $t('Privacy.Desc3_2_Section2') }}
            </div>
            <div>
              {{ $t('Privacy.Desc3_3_Section2') }} 
            </div>
            <div>
              {{ $t('Privacy.Desc3_4_Section2') }} 
            </div>
            <div>
              {{ $t('Privacy.Desc3_5_Section2') }}
            </div>
          </li>
        </ol>
      </section>
      <section class="content-section">
        <div class="section-h3">{{ $t('Privacy.Title_Section3') }}</div>
        <ol>
          <li>{{ $t('Privacy.Desc1_Section3') }}
            <div>{{ $t('Privacy.Desc1_1_Section3') }}</div>
            <div>{{ $t('Privacy.Desc1_2_Section3') }}</div>
            <div>{{ $t('Privacy.Desc1_3_Section3') }}</div>
            <div>{{ $t('Privacy.Desc1_4_Section3') }}</div>
            <div>{{ $t('Privacy.Desc1_5_Section3') }}</div>
            <div>{{ $t('Privacy.Desc1_6_Section3') }}</div>
            <div>{{ $t('Privacy.Desc1_7_Section3') }}</div>
          </li>
          <li>{{ $t('Privacy.Desc2_Section3') }}</li>
        </ol>
      </section>
      <section class="content-section">
        <div class="section-h3">{{ $t('Privacy.Title_Section4') }}</div>
        <ol>
          <li>{{ $t('Privacy.Desc1_Section4') }}</li>
          <li>
            {{ $t('Privacy.Desc2_Section4') }}
            <div> {{ $t('Privacy.Desc2_1_Section4') }}</div>
            <div> {{ $t('Privacy.Desc2_2_Section4') }}</div>
          </li>
        </ol>
      </section>
      <section class="content-section">
        <div class="section-h3">{{ $t('Privacy.Title_Section5') }}</div>
        <ol>
          <li>{{ $t('Privacy.Desc1_Section5') }}</li>
          <li>{{ $t('Privacy.Desc2_Section5') }}</li>
        </ol>
      </section>
      <section class="content-section">
        <div class="section-h3">{{ $t('Privacy.Title_Section6') }}</div>
        <div class="content-desc">
          {{ $t('Privacy.Desc_Section6') }}
        </div>
      </section>
      <section class="content-section">
        <div class="section-h3">{{ $t('Privacy.Title_Section7') }}</div>
        <div class="content-desc">
          {{ $t('Privacy.Desc_Section7') }} 
        </div>
      </section>
      <section class="content-section">
        <div class="section-h3">{{ $t('Privacy.Title_Section8') }}</div>
        <div class="content-desc">
          {{ $t('Privacy.Desc_Section8') }}
          <a 
            href="mailto:service@data-sports.tw"
            aria-label="開啟平台聯絡信箱"
            class="text-blue"
          >
            {{ $t('general.ContactUs') }}
          </a>
        </div>
      </section>
    </div>
  </el-main>
</template>

<script>
import MainContain from '@/components/Accessibility/MainContain.vue'

export default {
  name: 'Privacy',
  components: {
    MainContain
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.privacy-container {
  color: $text_dark;
  font-size: 1rem;
  .inner-container{
    max-width: 1200px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
  .content-section{
    padding: 0.625rem;
    .content-desc{
      line-height: 1.5;
      padding: 0.625rem 0;
    }
  }
}
</style>
